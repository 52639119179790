<template>
  <v-row>
    <v-col cols="12" md="6">
      <h5 v-t="'page.account.transactions.detail.support.reason'" class="green400--text text-body-2" />
      <p class="mb-0 text--secondary lh-1-2 text-caption" v-text="reason" />
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    transaction: {
      type: Object,
      required: true,
    },
  },
  computed: {
    reason() {
      return this.transaction.data?.reason ?? 'Unknown reason.'
    },
  },
}
</script>
